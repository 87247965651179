.inputCSS .MuiFormControl-root {
    width: inherit !important;
}

.inputCSS .MuiInputBase-input,
.inputCSS .MuiFormLabel-root {
    font: 400 1.5rem 'Montserrat', sans-serif !important;
}

.inputCSS .MuiFormLabel-root {
    color: black !important
}

.btn-relatorio {
    color: red !important;
    border-color: red !important;
    margin-top: 10px !important;
    border: red solid 1px !important;
}

.btn-disabled {
    color: grey !important;
    border-color: grey !important;
    margin-top: 10px !important;
    border: grey solid 1px !important;
}